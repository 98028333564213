<template>
  <!-- 搜索栏 -->
  <div class="search-bar flex-row-between-center">
    <!-- 搜索框 -->
    <span class="iconfont icon-search"></span>
    <input type="text" :value="keyword" @keyup.enter="handleSearch" @input="$emit('change', $event.target.value)" />
    <span v-if="keyword !== ''" class="iconfont icon-clear" @click="handleClear"></span>
    <span v-else class="placeholder"></span>

    <!-- 按钮 -->
    <div class="search-btn flex-center-center" @click="handleSearch">搜索</div>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',

  props: {
    keyword: {
      type: String,
      required: true,
      default: ''
    }
  },

  model: {
    prop: 'keyword',
    event: 'change'
  },

  data () {
    return {
      isSearch: false
    }
  },

  methods: {
    // 搜索
    handleSearch () {
      this.isSearch = true
      this.$emit('search')
    },
    // 清空
    handleClear () {
      this.$emit('change', '')
      if (this.isSearch) this.$emit('search')
    }
  }
}
</script>

<style scoped>
.search-bar {
  background: #dcdcdc;
  border-radius: 5px 15px 5px 5px;
}
.icon-search, .icon-clear {
  padding: 0 10px;
  font-size: 20px;
}
.search-bar input {
  width: calc(100% - 20px - (20px + 20px) * 2 - 15vw);
  font-size: 15px;
  padding: 5px 0;
  background: #dcdcdc;
  color: var(--normal-font-color);
}
.icon-clear {
  color: #777;
}
.search-btn {
  width: 15vw;
  padding: 6px 0;
  font-size: 14px;
  color: #fff;
  background: var(--main-color);
  border-radius: 0 15px 0 15px;
}
.placeholder {
  width: 20px;
  padding: 0 10px;
}
</style>
